import { init, piniaIntegration, browserTracingIntegration, replayIntegration } from '@sentry/nuxt'

const { public: { sentry, app, SINGA_API_URL } } = useRuntimeConfig()

if (sentry.dsn) {
	const router = useRouter()
	const escapedHost = SINGA_API_URL.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	const hostRegex = new RegExp(`^${escapedHost}`)

	init({
		dsn: sentry.dsn,
		environment: sentry.environment,

		release: app.release,
		integrations: [
			piniaIntegration(usePinia()),
			browserTracingIntegration({ router }),
			replayIntegration({
				maskAllText: false
			})
		],

		// Performance Monitoring
		tracesSampleRate: sentry.tracesSampleRate,
		tracePropagationTargets: [hostRegex, /^\//],

		// Set sampling rate for profiling - this is relative to tracesSampleRate
		profilesSampleRate: sentry.profilesSampleRate,

		replaysSessionSampleRate: sentry.replaysSessionSampleRate,
		replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate
	})
}
